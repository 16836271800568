import { render, staticRenderFns } from "./HighlightProduct.vue?vue&type=template&id=3c1553f3&scoped=true&"
import script from "./HighlightProduct.vue?vue&type=script&lang=ts&"
export * from "./HighlightProduct.vue?vue&type=script&lang=ts&"
import style0 from "./HighlightProduct.vue?vue&type=style&index=0&id=3c1553f3&prod&lang=scss&"
import style1 from "./HighlightProduct.vue?vue&type=style&index=1&id=3c1553f3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c1553f3",
  null
  
)

export default component.exports