


















import axios from "axios";
import {
    SfButton, SfLoader, SfProductCard, SfSection,
} from '@storefront-ui/vue';
import {
    defineComponent,
    ref,
    useContext,
    onMounted,
    useFetch,
    PropType,
    computed
} from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { SfBanner, SfBannerGrid } from "@storefront-ui/vue";
import HeroSection from "~/components/HeroSection.vue";
import MiniBanner from "~/components/MiniBanner.vue";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { useContent, useConfig } from "~/composables";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import ContentBlocks from "~/components/ContentBlocks.vue";
import Blog from "~/components/Blog/Tabs.vue";
import Preload from "~/components/Preload.vue";
import useApi from "~/composables/useApi";
import { CategoryTree } from "~/modules/GraphQL/types";
import CategoryProducts from "~/pages/CategoryProducts.gql";
import ContactUsForm from "~/components/ContactUsForm.vue";
import { useProductsWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import type { Product } from '~/modules/catalog/product/types';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import productGetters from '~/modules/catalog/product/getters/productGetters';

import ProductsGrid from "~/modules/catalog/product/components/ProductsGrid.vue";
import {
    useImage,
    useProduct,
} from '~/composables';
export default defineComponent({
    name: "HighlightProduct",
    components: {
        SfProductCard,
        ProductsGrid,
        CategoryProducts: () =>
            import(/* webpackPrefetch: true */ "~/components/CategoryProducts.vue"),
        SfButton
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const { query } = useApi();
        const categoryData = ref([]);
        const { isInWishlist, addOrRemoveItem } = useWishlist();
        const { getProductList, loading } = useProduct();
        const items = ref();
        const topContent = ref();
        const urlKey = ref();
        const catName = ref();
        const products = ref([]);

        const fetchHighlight = (async () => {
            //  const { fetch, fetchState } = useFetch(async () => {
            const { data } = await query<{ categoryList: [CategoryTree] }>(CategoryProducts, {
                filters: {
                    url_key: { eq: 'highlight-product' }
                },
                pageSize: 8
            });
            data.categoryList.map((value, index) => {
                items.value = value.products;
                topContent.value = value?.top_content;
                urlKey.value = value?.url_key;
                catName.value = value?.name;
            });

        });

        // fetch();

        onMounted(async () => {
            fetchHighlight();
        });


        // @ts-ignore
        return {
            categoryData,
            topContent,
            items,
            loading,
            urlKey,
            catName
        };
    },

});
